
import { AccountSummary } from "@/store/Account/AccountInterface";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default defineComponent({
  name: "scholarship-card",
  computed: {
    ...mapGetters("account", ["getAccountSummary"]),
    ...mapGetters("subs", ["getUserSubscription"]),
    accountSummary(): AccountSummary {
      return this.getAccountSummary;
    },
    scholarshipStartDate(): string {
      return this.accountSummary?.scholarshipStartDate
        ? this.formatDateTimezone(
            this.accountSummary.scholarshipStartDate.getTime()
          )
        : "-";
    },
    scholarshipEndDate(): string {
      return this.accountSummary?.scholarshipEndDate
        ? this.formatDateTimezone(
            this.accountSummary.scholarshipEndDate.getTime()
          )
        : "-";
    },
    basicAffiliatesNeededForScholarship(): number {
      return Math.ceil((300 - this.accountSummary.scholarshipPoints) / 100);
    },
    liteAffiliatesNeededForScholarship(): number {
      return Math.ceil((300 - this.accountSummary.scholarshipPoints) / 34);
    },
    isAcademyAndLicenseActive(): boolean {
      return (
        this.getUserSubscription("trading-academy").isActive &&
        this.getUserSubscription("distributor-license").isActive
      );
    },
  },
  methods: {
    getAccount(username: string) {
      this.$store
        .dispatch("account/initializeAccountSummary", username)
        .catch((error) => {
          console.error(error);
        });
    },
    localizedAffiliate(number: number) {
      return number == 1 ? "socio" : "socios";
    },
    localizedUpgrade(number: number) {
      return number == 1 ? "upgrade" : "upgrades";
    },
    scholarshipPointsClass(points: number) {
      const classes = "fa-stack-1x fa-lg";
      if (this.accountSummary.scholarshipPoints >= points) {
        return "fas fa-user text-orange " + classes;
      } else {
        return "far fa-user text-light-gray " + classes;
      }
    },
    scholarshipPartialPercentageStyle(points: number) {
      return {
        height: this.scholarshipPartialPercentage(points) + "%",
        overflow: "hidden",
      };
    },
    scholarshipPartialPercentage(points: number) {
      if (this.accountSummary.scholarshipPoints >= points) return 100;
      return (this.accountSummary.scholarshipPoints - points) * -1;
    },
    formatDateTimezone(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss z");
    },
  },
});
